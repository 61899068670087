import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { ReactComponent as Position1Icon } from '../../svgs/nav/Position1.svg';
import { ReactComponent as RightArrow } from '../../svgs/icons/right-arrow.svg';
import { ReactComponent as Position3Icon } from '../../svgs/nav/Position3.svg';
import { ReactComponent as JaneDediIcon } from '../../svgs/nav/JaneDediNav.svg';
import { ReactComponent as JaneSellsIcon } from '../../svgs/nav/JaneSellsNav.svg';
import { ReactComponent as JaneVPSIcon } from '../../svgs/nav/JaneVPSNav.svg';
import { ReactComponent as Position2Icon } from '../../svgs/nav/Position2.svg';
import { ReactComponent as Position5Icon } from '../../svgs/nav/Position5.svg';
import { ReactComponent as Position4Icon } from '../../svgs/nav/Position4.svg';
import useWindowSize from '../../utils/useWindowSize';

const HostingNavButtonsWrapper = styled.section`
	&.hostjane-main__hostingNavButtons {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		align-items: center;
		justify-content: space-between;
		padding: 0px 40px;
		max-width: 1440px;
		margin: 0 auto;
		@media screen and (max-width: 1280px) {
			padding: 0px 10px;
		}
		@media screen and (max-width: 810px) {
			grid-template-columns: repeat(4, 1fr);
		}
		@media screen and (max-width: 810px) and (min-width: 767px) {
		}
		.hostjane-main__hostingNavButtons__btn {
			text-decoration: none;
			width: 100%;
			height: 100%;
			border-right: 1px dashed rgba(128, 128, 128, 0.2);
			padding: 15px 15px 15px 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.2s linear;
			&:hover {
				background-color: #f2f2f2;
				text-decoration: none;
				.hostjane-main__hostingNavButtons__btn__arrow {
					transform: translateX(2px);
				}
			}
			&:last-of-type {
				border-right: none;
			}
			padding-left: 10px;
			&:first-of-type {
				padding-left: 0px;
			}
			@media screen and (max-width: 1024px) {
				padding-right: 5px;
			}
			@media screen and (max-width: 576px) {
				padding-left: 5px;
				border-right: 1px dashed rgba(128, 128, 128, 0.2);
			}

			@media screen and (max-width: 810px) and (min-width: 767px) {
				&:first-of-type {
					display: none;
				}
			}
			.hostjane-main__hostingNavButtons__btn__icon {
				svg {
					width: 30px;
					height: 30px;
				}
				&.more-space {
					svg {
						width: 32px;
						height: 32px;
					}
				}
				&.main {
					svg {
						width: 40px;
						height: 40px;
					}
				}
				@media screen and (max-width: 767px) {
					svg {
						width: 40px;
						height: 40px;
					}
				}
				@media screen and (max-width: 576px) {
					padding-left: 10px;
					&:first-of-type {
						padding-left: 0px;
					}
					width: 25px;
					height: 25px;
					svg {
						width: 25px;
						height: 25px;
					}
				}
			}
			.hostjane-main__hostingNavButtons__btn__content {
				h2 {
					font-size: 17px;
					font-weight: bold;
					margin: 0;
					margin-left: 20px;
					white-space: nowrap;
					color: #000;
					&.main {
						margin-left: 20px;
						font-weight: bold;
					}
					&.more-space {
						margin-left: 20px;
					}
					@media screen and (max-width: 1192px) {
						max-width: 96px;
						white-space: inherit;
					}

					@media screen and (max-width: 1024px) {
						margin-left: 10px;
					}
					@media screen and (max-width: 576px) {
						margin-left: 10px;
						font-size: 10px;
					}
				}
				p {
					margin: 0;
					font-size: 16px;
					font-family: inherit;
					color: #007bff;
					line-height: 1;
					font-weight: 400;
					margin-left: 20px;
					white-space: nowrap;
					&.main {
						margin-left: 20px;
						font-size: 14px;
						color: black;
						font-weight: normal;
					}
					&.more-space {
						margin-left: 20px;
					}
					@media screen and (max-width: 1192px) {
						white-space: inherit;
						// margin-left: 0;
					}
					@media screen and (max-width: 1024px) {
						margin-left: 10px;
					}
				}
			}
			.hostjane-main__hostingNavButtons__btn__arrow {
				margin-left: 20px;
				transition: all 100ms ease-out;
				@media screen and (max-width: 1280px) {
					margin-left: 10px;
				}
				svg {
					width: 18px;
					height: 18px;
					fill: #3c89ff;
					stroke: #3c89ff;
					stroke-width: 2px;
				}
				@media screen and (max-width: 1024px) {
					display: none;
				}
			}
		}
	}
`;

const HostingNavButtons = () => {
	const mobileData = [
		{
			title: 'WordPress',
			hostingIcon: <Position1Icon />,
			href: 'https://cloud.hostjane.com/wordpress/',
		},
		{
			title: 'VPS',
			hostingIcon: <Position3Icon />,
			href: 'https://cloud.hostjane.com/vps-hosting/',
		},
		{
			title: 'Cloud',
			hostingIcon: <Position2Icon />,
			href: 'https://cloud.hostjane.com/cloud/',
		},
		{
			title: 'Devs',
			hostingIcon: <Position4Icon />,
			href: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development',
		},	
	];
	const desktopData = [
		{
			hostingIcon: <Position1Icon />,
			title: 'WordPress Hosting',
			subtitle: 'Managed on AWS',
			arrow: '',
			href: 'https://cloud.hostjane.com/wordpress/',
		},
		{
			hostingIcon: <Position2Icon />,
			title: 'Cloud servers',
			subtitle: 'Run on Amazon EC2',
			arrow: '',
			href: 'https://cloud.hostjane.com/cloud/',
		},
		{
			hostingIcon: <Position3Icon />,
			title: 'Managed VPS',
			subtitle: 'Amazon EC2 Bare Metal',
			arrow: '',
			isMain: true,
			href: 'https://cloud.hostjane.com/vps-hosting/',
		},
		{
			hostingIcon: <Position4Icon />,
			title: 'Web Developers',
			subtitle: 'Browse talent',
			arrow: '',
			vps: true,
			href: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development',
		},
		{
			hostingIcon: <Position5Icon />,
			title: 'Online Tutors',
			subtitle: 'Learn new skills',
			arrow: '',
			href: 'https://www.hostjane.com/marketplace/categories/online-tutors',
			moreSpace: true,
		},
	];
	const [data, setData] = useState(desktopData);
	const windowWidth = useWindowSize();

	return (
		<HostingNavButtonsWrapper className="hostjane-main__hostingNavButtons">
			{windowWidth[0] >= 768 &&
				desktopData.map(
					({
						hostingIcon,
						title,
						subtitle,
						arrow,
						isMain,
						moreSpace,
						vps,
						href,
					}) => {
						return (
							<a
								href={href}
								className="hostjane-main__hostingNavButtons__btn"
							>
								<span
									className={classnames(
										'hostjane-main__hostingNavButtons__btn__icon',
										isMain && 'main',
										moreSpace && 'more-space',
										vps && 'vps'
									)}
								>
									{hostingIcon}
								</span>
								<div className="hostjane-main__hostingNavButtons__btn__content">
									<h2
										className={classnames(
											isMain && 'main',
											moreSpace && 'more-space',
											vps && 'vps'
										)}
									>
										{title}
									</h2>
									<p
										className={classnames(
											isMain && 'main',
											moreSpace && 'more-space',
											vps && 'vps'
										)}
									>
										{subtitle}
									</p>
								</div>
								<span className="hostjane-main__hostingNavButtons__btn__arrow">
									<RightArrow />
								</span>
							</a>
						);
					}
				)}
			{windowWidth[0] < 768 &&
				mobileData.map(
					({ hostingIcon, title, subtitle, arrow, isMain, href }) => {
						return (
							<a
								href={href}
								className="hostjane-main__hostingNavButtons__btn"
							>
								<span className="hostjane-main__hostingNavButtons__btn__icon">
									{hostingIcon}
								</span>
								<div className="hostjane-main__hostingNavButtons__btn__content">
									<h2 className={isMain && 'main'}>
										{title}
									</h2>
									<p className={isMain && 'main'}>
										{subtitle}
									</p>
								</div>
								<span className="hostjane-main__hostingNavButtons__btn__arrow">
									<RightArrow />
								</span>
							</a>
						);
					}
				)}
		</HostingNavButtonsWrapper>
	);
};

export default HostingNavButtons;
