import React from 'react';
import { ReactComponent as FreelancersIcon } from './freelancers.svg';
import { ReactComponent as WebHostingIcon } from './web-hosting.svg';

export default {
	title: 'Buy servers, hire talent.',
	leftColumn: {
		title: 'Hire people',
		icon: FreelancersIcon,
		description: 'Ready to work freelancers',
		ctas: [
			{
				type: 'primary',
				label: 'Continue',
				link: '/marketplace',
				internal: false,
			},
			{
				type: 'secondary',
				label: 'Become a Seller',
				link: 'https://www.hostjane.com/sell',
				internal: false,
			},
		],
	},
	rightColumn: {
		title: 'Web hosting',
		icon: WebHostingIcon,
		description: 'Amazon VPS and cloud computes',
		ctas: [
			{
				type: 'primary',
				label: 'Continue',
				link: 'https://cloud.hostjane.com',
				internal: false,
			},
			{
				type: 'secondary',
				label: 'Login to Hosting',
				link: 'https://cloud.hostjane.com/hosting/login',
				internal: true,
			},
		],
	},
};
